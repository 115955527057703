export const environment = {
  production: true,
  webApiUrl: 'https://api.bucket.issofttech.xyz/',
  mediaApiUrl: 'https://api.bucket.issofttech.xyz/drive/',
  // mediaApiUrl: 'https://bucketapps.xyz/drive/',
  // reportTepmURL: 'https://is-report-4c07c.web.app/',
  reportTepmURL: 'https://report.issofttech.xyz/',
  bucket: 'bucket/',
  pos: 'pos/',
  orderDetails: 'order-details/',
  purchaseOrder: 'purchase-order/',
  paymentDetails: 'payment-details/',
  invoiceDetails: 'invoice-details/',
  stockHistory: 'stock-history/',
  transferOrderDetalis: 'transfer-order/details/',
  disbursementDetails: 'disbursement-details/',
  loanAccountDetails: 'loan-account/details/',
  windowLocationHostname: 'bucket.issofttech.xyz',
  thermalPrint: 'thermal-print/',
};
