import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from '../Services/category.service';
import { Category } from '../Models/DomainModel/Inventory/Category';
import { AccountService } from '../Services/account.service';
import { Account, AccountDetails } from '../Models/DomainModel/User/Account';
import { HttpResponse } from '@angular/common/http';
import { HelperService } from '../Services/helper.service';
import { MessageService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  userName: string;
  password: string;

  loginObj: Account = {} as any;
  hostname: string = '';
  constructor(
    private router: Router,
    private accountService: AccountService,
    public helperService: HelperService,
    private titleService: Title,
    private messageService: MessageService) { }

  ngOnInit(): void {
    // if (this.helperService.isAuthenticated()) {
    //   this.router.navigate(['/admin']);
    // }
    this.titleService.setTitle(this.helperService.title);
  }

  logIn() {
    this.loginObj.email = this.userName;
    this.loginObj.mobileNo = this.userName;
    this.loginObj.password = this.password;
    if (!this.helperService.isAuthenticated()) {
      switch (this.helperService.hostname) {
        case 'bucket.issofttech.xyz':
          this.employeeLogInEndPoint();
          break;
        case 'seller.issofttech.xyz':
          this.sellerLogInEndPoint();
          break;
        case 'warehouse.issofttech.xyz':
          this.warehouseLogInEndPoint();
          break;
        default:
          this.employeeLogInEndPoint();
          break;
      }
    }
    else {
      this.router.navigate(['/admin']);
    }
    // this.confirmationService.confirm({
    //   message: 'Are you sure that you want to perform this action?',
    //   key: 'confirm_common',
    //   accept: () => {
    //     Actual logic to perform a confirmation
    //   }
    // });
  }

  private employeeLogInEndPoint() {
    this.accountService.employeeWeblogin(this.loginObj).subscribe((response: HttpResponse<any>) => {
      if (response.status === 200) {
        if (response.body.Employee) {
          const role = response.body.Users.Role;
          this.helperService.userDetails.Employee = response.body.Employee;
          this.helperService.userRolePermission = response.body.Users.Role.RolePermissions;
          this.helperService.role = response.body.Users.Role;
          // this.helperService.isAuthenticated = true;
          const accessToken = response.body.AccessToken;
          // localStorage.setItem('accessToken', accessToken.token);
          localStorage.setItem('fullName', response.body.Employee.FirstName + ' ' + response.body.Employee.LastName);
          localStorage.setItem('roleName', response.body.Users.Role.RoleName);
          this.titleService.setTitle(this.helperService.title + ' | ' + response.body.Users.Role.RoleName);
          localStorage.setItem('accessToken', accessToken.Token);
          localStorage.setItem('userId', response.body.Users.UserId);
          localStorage.setItem('roleId', role.RoleId);
          this.router.navigate(['/admin']);
        }
        else {
          this.messageService.add({ key: 'error_common', sticky: true, severity: 'error', summary: 'Invalid Login', detail: 'Combination of username/password is incorrect' });
        }
      }
      else{
        this.messageService.add({ key: 'error_common', sticky: true, severity: 'error', summary: 'Invalid Login', detail: 'Combination of username/password is incorrect' });
      }
    });
  }

  private sellerLogInEndPoint() {
    this.accountService.sellerWeblogin(this.loginObj).subscribe((response: HttpResponse<any>) => {
      if (response.status === 200) {
        if (response.body.Seller) {
          const role = response.body.Users.Role;
          this.helperService.userDetails.Seller = response.body.Seller;
          this.helperService.userRolePermission = response.body.Users.Role.RolePermissions;
          this.helperService.role = response.body.Users.Role;
          // this.helperService.isAuthenticated = true;
          const accessToken = response.body.AccessToken;
          // localStorage.setItem('accessToken', accessToken.token);
          localStorage.setItem('sellerBusinessName', response.body.Seller.SellerDetails.SellerBusinessName);
          localStorage.setItem('fullName', response.body.Seller.FirstName + ' ' + response.body.Seller.LastName);
          localStorage.setItem('roleName', response.body.Users.Role.RoleName);
          this.titleService.setTitle(this.helperService.title + ' | ' + response.body.Users.Role.RoleName);
          localStorage.setItem('accessToken', accessToken.Token);
          localStorage.setItem('userId', response.body.Users.UserId);
          localStorage.setItem('roleId', role.RoleId);
          this.router.navigate(['/admin']);
        }
        else {
          this.messageService.add({ key: 'error_common', sticky: true, severity: 'error', summary: 'Invalid Login', detail: 'Combination of username/password is incorrect' });
        }
      }
      else{
        this.messageService.add({ key: 'error_common', sticky: true, severity: 'error', summary: 'Invalid Login', detail: 'Combination of username/password is incorrect' });
      }
    });
  }
  private warehouseLogInEndPoint() {
    this.accountService.warehouseWeblogin(this.loginObj).subscribe((response: HttpResponse<any>) => {
      if (response.status === 200) {
        if (response.body.WarehouseUser) {
          const role = response.body.Users.Role;
          this.helperService.userDetails.WarehouseUser = response.body.WarehouseUser;
          this.helperService.userRolePermission = response.body.Users.Role.RolePermissions;
          this.helperService.role = response.body.Users.Role;
          // this.helperService.isAuthenticated = true;
          const accessToken = response.body.AccessToken;
          // localStorage.setItem('accessToken', accessToken.token);
          localStorage.setItem('fullName', response.body.WarehouseUser.FirstName + ' ' + response.body.WarehouseUser.LastName);
          localStorage.setItem('roleName', response.body.Users.Role.RoleName);
          this.titleService.setTitle(this.helperService.title + ' | ' + response.body.Users.Role.RoleName);
          localStorage.setItem('accessToken', accessToken.Token);
          localStorage.setItem('userId', response.body.Users.UserId);
          localStorage.setItem('sellerId', response.body.WarehouseUser.Warehouse.SellerId);
          localStorage.setItem('sellerBusinessName', response.body.WarehouseUser.Warehouse.Seller.SellerDetails.SellerBusinessName);
          localStorage.setItem('warehouseName', response.body.WarehouseUser.Warehouse.WarehouseName);
          localStorage.setItem('roleId', role.RoleId);
          this.router.navigate(['/admin']);
        }
        else {
          this.messageService.add({ key: 'error_common', sticky: true, severity: 'error', summary: 'Invalid Login', detail: 'Combination of username/password is incorrect' });
        }
      }
      else{
        this.messageService.add({ key: 'error_common', sticky: true, severity: 'error', summary: 'Invalid Login', detail: 'Combination of username/password is incorrect' });
      }
    });
  }
}
