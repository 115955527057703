import { Injectable } from '@angular/core';
import { Role } from '../Models/DomainModel/User/Role';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Menu } from '../Models/DomainModel/Initialize/Menu';
import { RolePermission } from '../Models/DomainModel/User/RolePermission';
import { HelperValidator } from '../Models/ViewModels/helper/helperValidator';
import { Title } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { UserDetailsViewModel } from '../Models/ViewModels/User/UserDetailsViewModel';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public userRolePermission: RolePermission[] = [];
  public userDetails: UserDetailsViewModel = new UserDetailsViewModel();
  public role: Role;

  public menus: Menu[] = [];
  public url = '';
  // public isAuthenticated: boolean = false;
  private loginUrl = '/login';
  public helper: HelperValidator = new HelperValidator();
  public title: string = '';
  public hostname: string = '';
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('accessToken');
    const roleName = localStorage.getItem('roleName');
    const userId = localStorage.getItem('userId');
    if (roleName != null && userId != null) {
      this.titleService.setTitle(this.title + ' | ' + roleName);
    }
    else {
      return false;
    }
    if (token != 'undefined' && token != null) {
      console.log(this.jwtHelper.isTokenExpired(token));
      console.log(this.jwtHelper.decodeToken(token));
      console.log(this.jwtHelper.getTokenExpirationDate(token));
      console.log(new Date());
      if (this.jwtHelper.getTokenExpirationDate(token).getTime().valueOf() < Date.now()) {
        console.log('Token Expir');
      }
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
    // Check whether the token is expired and return
    // true or false
  }
  public setRedirectUrl(url) {
    this.url = url;
  }
  public getLoginUrl(): string {
    return this.loginUrl;
  }
  public getItem(item: string): string {
    return localStorage.getItem(item) ? localStorage.getItem(item) : '';
  }
  constructor(public jwtHelper: JwtHelperService, private titleService: Title) {
    // this.helper.SelectValidator = '^(0+[1-9]|[1-9])[0-9]*$';
    // this.helper.EmailValidator = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$';
    // this.helper.MobileNumberValidator = '^[0-9]{10}$';
    // this.helper.UserNameValidator = '^[a-z A-Z0-9_-]{6,25}$';
    // this.helper.AddressValidator = '^[a-z A-Z0-9_-]{8,25}$';
    // this.helper.GstValidator = '^[a-zA-Z0-9]{15}$';
    // this.helper.PincodeValidator = '^[0-9]{6}$';
    // this.helper.TwoDigitValidator = '^\d*\.?\d{0,2}$';
    // this.helper.CurrencyValidator = '/^(?:\d+|\d{1,2},(?:\d{2},)*\d{3})(?:\.\d{2})?$/';
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

  public numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  public parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }
  public isSellerOrWarehouse(): boolean {
    if (this.hostname === 'seller.issofttech.xyz') {
      return true;
    }
    else{
      return false;
    }
  }
}
