import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HelperService } from '../helper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public helperService: HelperService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // console.log(state.url);
    if (!this.helperService.isAuthenticated() && state.url !== '/login') {
      this.router.navigate(['/login']);
      return false;
    }
    else if (state.url === '/login' && this.helperService.isAuthenticated()) {
      this.router.navigate(['/admin']);
      return false;
    }
    return true;
  }
}
