export class HelperValidator {
    SelectValidator: string = '^(0+[1-9]|[1-9])[0-9]*$';
    EmailValidator: string = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$';
    MobileNumberValidator: string = '^[0-9]{10}$';
    UserNameValidator: string = '^[a-z A-Z0-9_-]{6,25}$';
    AddressValidator: string = '^[a-z A-Z0-9_-]{8,25}$';
    GstValidator: string = '^[a-zA-Z0-9]{15}$';
    TwoDigitValidator: string = '^\d*\.?\d{0,2}$';
    PincodeValidator: string = '^[0-9]{6}$';
    CurrencyValidator: string = '/^(?:\d+|\d{1,2},(?:\d{2},)*\d{3})(?:\.\d{2})?$/';
    IndianCurrencySymbol: string = '₹';
    PercentageSymbol: string = '%';
}
