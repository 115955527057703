import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Account, AccountDetails } from '../Models/DomainModel/User/Account';
import { observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  userLoginUrl = environment.webApiUrl + 'employee-login/web';
  sellerUserLoginUrl = environment.webApiUrl + 'seller-login/web';
  warehouseUserLoginUrl = environment.webApiUrl + 'warehouse-login/web';
  constructor(private http: HttpClient) { }

  employeeWeblogin(login: Account) {
    return this.http.post<AccountDetails>(this.userLoginUrl, login, { observe: 'response' });
  }
  sellerWeblogin(login: Account) {
    return this.http.post<AccountDetails>(this.sellerUserLoginUrl, login, { observe: 'response' });
  }
  warehouseWeblogin(login: Account) {
    return this.http.post<AccountDetails>(this.warehouseUserLoginUrl, login, { observe: 'response' });
  }
}
