import { Component, OnInit } from '@angular/core';
import { HelperService } from './Services/helper.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private messageService: MessageService,
    private titleService: Title,
    public helperService: HelperService) { }

  ngOnInit(): void {
    // if (this.helperService.isAuthenticated()) {
    //   this.router.navigate(['/admin']);
    // }
    if (window.location.hostname == 'localhost') {
      this.helperService.hostname = environment.windowLocationHostname;
    }
    else {
      this.helperService.hostname = window.location.hostname;
    }
    switch (this.helperService.hostname) {
      case 'bucket.issofttech.xyz':
        this.helperService.title = 'iS Bucket';
        break;
      case 'seller.issofttech.xyz':
        this.helperService.title = 'iS Seller';
        break;
      case 'warehouse.issofttech.xyz':
        this.helperService.title = 'iS Warehouse';
        break;
      default:
        this.helperService.title = 'iS Bucket : Demo';
        break;
    }
    this.titleService.setTitle(this.helperService.title);
  }

  closeError() {
    this.messageService.clear('common_error');
    this.messageService.clear();
  }

  closeSuccess() {
    this.messageService.clear('common_suceess');
    this.messageService.clear();
  }
}
