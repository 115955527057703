// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  webApiUrl: 'https://api.bucket.issofttech.xyz/',
  // webApiUrl: 'http://localhost:51393/',
  // webApiUrl: 'http://10.173.62.40/iSBucketAPI/',
  // webApiUrl: 'https://bucketapps.xyz/',
  // mediaApiUrl: 'http://localhost:51393/drive/'
  // mediaApiUrl: 'https://bucketapps.xyz/drive/',
  // mediaApiUrl: 'http://api.drive.issofttech.xyz/',
  mediaApiUrl: 'https://api.bucket.issofttech.xyz/drive/',
  // reportTepmURL: 'http://localhost:4100/',
  // reportTepmURL: 'https://report.bucketapps.xyz/',
  reportTepmURL: 'https://report.issofttech.xyz/',
  bucket: 'bucket/',
  pos: 'pos/',
  orderDetails: 'order-details/',
  purchaseOrder: 'purchase-order/',
  paymentDetails: 'payment-details/',
  invoiceDetails: 'invoice-details/',
  stockHistory: 'stock-history/',
  transferOrderDetalis: 'transfer-order/details/',
  disbursementDetails: 'disbursement-details/',
  loanAccountDetails: 'loan-account/details/',
  windowLocationHostname: 'bucket.issofttech.xyz',
  thermalPrint: 'thermal-print/',
  // windowLocatiThermalPrint: 'warehouse.issofttech.xyz',w
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
