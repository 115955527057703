<div class="container">
    <div class="container login-container">
        <div class="row">
            <div class="col-md-12 login-form">
                <div class="form-group">
                    <h3><img src="../../assets/images/icon_is.png" style="width: 32px;">
                        Login for {{helperService.title}}</h3>
                </div>
                <form #logInForm="ngForm">
                    <div class="form-group">
                        <input type="text" class="form-control" id="name" placeholder="Your Username *" required
                            [(ngModel)]="userName" name="name" #name="ngModel" autocomplete="off">
                        <div [hidden]="name.valid || name.pristine" class="error-text">
                            Name is required
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Your Password *" value="" required
                            [(ngModel)]="password" name="userPassword" #userPassword="ngModel" />
                        <div [hidden]="userPassword.valid || userPassword.pristine" class="error-text">
                            password is required
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" class="btn btn-gradient-primary btn-fw" value="Login" (click)="logIn()"
                            [disabled]="!logInForm.form.valid">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>