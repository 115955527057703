import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpRequestHandler } from './Helpers/HttpRequestHandler';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { LoginComponent } from './login/login.component';
import { CategoryService } from './Services/category.service';
import { HelperService } from './Services/helper.service';
import { AccountService } from './Services/account.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment.prod';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { AppInformationComponent } from './app-information/app-information.component';
import { FooterComponent } from './footer/footer.component';
// import { AgmCoreModule } from '@agm/core';

export function tokenGetter() {
  return localStorage.getItem('accessToken');
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppInformationComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ConfirmDialogModule,
    FormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    ToastModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [environment.webApiUrl],
        blacklistedRoutes: ['localhost:3001/auth/']
      }
    }),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBZyfZOCOr0IX2JI1i6YMIjsLy-x2wRci8',
    //   libraries: ['places']
    // }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestHandler, multi: true },
    ConfirmationService, MessageService,
    HelperService, AccountService, Title
  ],
  bootstrap: [AppComponent],

})
export class AppModule {

}
